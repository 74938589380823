import React from 'react';
// utilities
import root from 'window-or-global';
// styles
import "../styles-josh.css";

import Helmet from 'react-helmet';

const favicon = require("../images/construction.png").default;

class ConstructionPage extends React.Component {

  componentDidMount() {
    this.handleBtnClick = this.handleBtnClick.bind(this);
  }

  handleBtnClick() {
    console.log('handlebutnclick');
    root.history.back();
  }


  render() {
    if (this.props.fullscreen) {
      styles.background.height = '100vh';
    }

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Under construction</title>
          <link rel="shortcut icon" href={favicon} />
          <link rel="apple-touch-icon" href={favicon} />
        </Helmet>
        <div style={styles.background}>
          <div style={styles.backgroundOverlay}></div>
          <div className="errorpage-box">
            <h1 className="errorpage-title">Pardon us...</h1>
            <p className="errorpage-subtitle">This page isn't quite ready for primetime yet, but we're working on it.</p>
            <button
              className="errorpage-button"
              onClick={this.handleBtnClick}
            >Go Back</button>
          </div>
        </div>
      </>
    );
  }
}

const styles = {
  background: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    backgroundImage: `url(${require("../images/construction.jpg").default}`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media screen and (minWidth: 768px)': {
      width: '100vw'
    }
  },
  backgroundOverlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.7)'
    // background: 'linear-gradient(to bottom right, rgba(96, 195, 243, 0.9) 0%, rgba(123, 16, 230, 0.9) 80%)'
  },
 
  
}


export default ConstructionPage;
