//add this method for preparing json for custom question rendering to web survey 
export const customQuestionRendering = function (data) {
  console.log('{}{}{}{}{}}{}{}{}{}  customQuestionRendering called');

  if (data && data.pages) {
    console.log('had data and pages, will continue');

    data.pages.map((page) => {
      console.log('moving through the pages');

      page.elements && page.elements.map((element) => {
        console.log('moving through the element');

        if (element.type == "mcadditionalinput") {
          let choices = [];
          if (element.tableItems && choices.length == 0) {
            element.tableItems.forEach(item => {
              choices.push(item.value);
            });
          }
          element.type = "matrixdropdown";
          element.columns = [
            {
              name: "List",
              cellType: "checkbox",
            },
            {
              name: "Optional Comment",
              cellType: "text",
            },
          ];
          element.choices = [" "];
          element.rows = choices;
        }

        if (element.type == "groupedradio") {
          console.log('found for groupedradio');
          element.type = "matrixdropdown";

          console.log('setting the state?');
          this.setState({
            newGroupQuestion: [...this.state.newGroupQuestion, element],
          });

          console.log('calling groupingQuestion with element: ', element);
          groupingQuestion(element);
        }
      });
    });
  }

  console.log('{}{}{}{}{}}{}{}{}{} customQuestionRending returning data: ', data);
  return data;
};

const groupingQuestion = function (element) {
  console.log("-------------------------------- groupingQuestion called --------------------------------");

  element.type = "panel";
  element.title = element.name;
  
  let radioGroups = [];

  console.log("about to get into element forEach", element);

  element.groupTableItems.forEach((item) => {
    console.log("looking at item: ", item);
    item.choices = [];
    item.name = item.groupname;
    item.type = "radiogroup";
    item.quid = element.quid;

    console.log("looking at item, with additions: ", item);

    console.log('radioGroups: ', radioGroups);

    // do we already have this group?
    let commonObj = radioGroups.find((objData) => {
      console.log('!!!! this is the first thing in find -- ', objData);

      if (objData && objData.groupname === item.groupname && item.hasOwnProperty('value')) {
        console.log("pushing something into choices ", item);
        objData.choices.push(item.value);
        console.log('groupingQuestion will return true');
        return true;
      } else {
        console.log('groupname did not equal item.groupname');
      }
    });

    console.log('commonObj: ', commonObj);
    
    // no, we did not have this radiogroup yet
    if (!commonObj && item.hasOwnProperty('value')) {
      console.log('did not have a common object, pushing to item.choices and radioGroups: ', item);
      item.choices.push(item.value);
      radioGroups.push(item);
    } else {
      console.log('second if condition not met');
    }

    console.log("done with item, moving on to next");
  });

  element.elements = radioGroups;

  console.log('-------------------------------- groupingQuestion has ended --------------------------------');

};

export const customResponsePreparing = function (resultData, returnObj) {
  console.log("customResponsePreparing called");
  let radioGroups = [];

  resultData.forEach((item) => {
    item.choices = [];
    let commonObj = radioGroups.find((objData) => {
      if (objData && objData.quid === item.quid) {
        objData.choices.push(item.value);
        return true;
      }
    });
    if (!commonObj) {
      item.choices.push(item.value);
      radioGroups.push(item);
    }
  });

  radioGroups.forEach(item => {
    if (item.choices.length > 1) {
      this.state.newGroupQuestion.forEach((element) => {
        if (element.quid == item.quid && element.groupTableItems.length) {
          let itemName = element.groupTableItems[0].value;
          returnObj[item.quid] = {
            [itemName]: item.choices,
          };
        }
      });
    }
  });
  return returnObj;
};
