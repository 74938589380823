import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

// pages
import SurveyPage from "./pages/SurveyPage";
import ErrorPage from "./pages/ErrorPage";
import StatusPage from "./pages/StatusPage";
import UnsubscribePage from "./pages/UnsubscribePage";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/getStatus" component={StatusPage} />
        <Route path="/surveys/:campaignId" component={SurveyPage} />
        <Route path="/unsubscribe" component={UnsubscribePage} />
        <Route path="/privacy-policy" component={ErrorPage} />
        <Route path="/cookie-policy" component={ErrorPage} />
        <Route path="*" component={ErrorPage} />
      </Switch>
    </Router>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);