import React from "react";
import axios from "axios";
import ErrorPage from "./ErrorPage";
// styles
import "../Unsubscribe.css";
class UnsubscribePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      unsubscribe: false,
    };
  }
  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    document.title = 'Unsubscribe';
    const email =
      params.get("email") !== null ? params.get("email").toLowerCase() : null;
    const customerId =
      params.get("customerid") !== null
        ? params.get("customerid").toLowerCase()
        : null;
    const userId =
      params.get("userid") !== null ? params.get("userid").toLowerCase() : null;
    const campaigncode =
      params.get("campaigncode") !== null
        ? params.get("campaigncode").toLowerCase()
        : null;
    const requestBody = {
      email: email,
      customerId: customerId,
      userId: userId,
      isUnsubscribe: true,
      campaigncode: campaigncode,
    };
    console.log("requestBody : ",requestBody);
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}unsubscribemember`,
        requestBody
      )
      .then((response) => {
        if (response.data.description == "Member unsubscribed successfully") {
          console.log("isValidUser : ", response.data);
          this.setState({ unsubscribe: true });
        }
        this.setState({ loading: false });
      });
  }
  render() {
    const { loading, unsubscribe } = this.state;
    if (!loading) {
      if (unsubscribe) {
        return (
          <>
            <div className="content">
              <div className="wrapper-1">
                <div className="wrapper-2">
                  <h2>Unsubscribe Successful.</h2>
                  <p>You have successfully unsubscribed from communications.</p>
                  <p>Thank you!</p>
                </div>
              </div>
            </div>
          </>
        );
      } else {
        return <ErrorPage />;
      }
    } else {
      return (
        <div className="loader-wrapper">
          <div id="advanced" className="circle"></div>
          <div className="image"></div>
        </div>
      );
    }
  }
}

export default UnsubscribePage;
