import React from "react";

import ErrorPage from "./ErrorPage";
import UnavailablePage from "./UnavailablePage";
// tools
import $ from 'jquery';
import { v4 as uuid } from "uuid";
import convertColorToRGB from "../tools/convertColorToRGB";
import lightenDarkenColor from "../tools/lightenDarkenColor";
// survey
import * as Survey from "survey-react";
import 'jquery-bar-rating';
import { FaInfoCircle } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from 'glamor';

import Fingerprint2 from "fingerprintjs2";

import Helmet from "react-helmet";

// this at least helps it to show up...
import 'ckeditor4';

import "jquery-ui/ui/widgets/datepicker";
import 'emotion-ratings/dist/emotion-ratings';
import "select2/dist/js/select2.js";
import "jquery-bar-rating";
// this one seems to have to be here for some reason?  don't move it above the other datepicker!!!
import "bootstrap-datepicker";
import "easy-autocomplete/dist/jquery.easy-autocomplete";

// bootstrap datepicker is way nicer, let's you zoom out to years and stuff, use that one rather than jqueryuidatepicker
// also note i've removed ckeditor from here -- it doesn't make sense -- i thought it was just for using to build out the html to display, and we can use that, but when it's included here and used as a question type, it actually gives end users an html editor to provide their answers in, which doesn't make a whole lot of sense
// will look at modifying things on the admin side to enable using ck editor to edit the html input type

import { jqueryuidatepicker, nouislider, sortablejs, icheck, select2, inputmask, jquerybarrating, select2tagbox, autocomplete, prettycheckbox, bootstrapslider, microphone, emotionsratings, bootstrapdatepicker } from 'surveyjs-widgets';

/* Custom widgets css files - started */
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker3.css";
import "survey-react/survey.css";
import "jquery-ui/themes/base/all.css";
import "nouislider/distribute/nouislider.css";
import "select2/dist/css/select2.css";
import "select2/dist/css/select2.min.css";

import "bootstrap-slider/dist/css/bootstrap-slider.css";
import 'jquery-bar-rating/dist/themes/bars-1to10.css';
import 'jquery-bar-rating/dist/themes/bars-horizontal.css';
import 'jquery-bar-rating/dist/themes/bars-movie.css';
import 'jquery-bar-rating/dist/themes/bars-pill.css';
import "jquery-bar-rating/dist/themes/bars-reversed.css";
import "jquery-bar-rating/dist/themes/bars-square.css";
import "jquery-bar-rating/dist/themes/bootstrap-stars.css";
import "jquery-bar-rating/dist/themes/css-stars.css";
import "jquery-bar-rating/dist/themes/fontawesome-stars.css";
import "jquery-bar-rating/dist/themes/fontawesome-stars-o.css";
import "pretty-checkbox/dist/pretty-checkbox.css";
import "easy-autocomplete/dist/easy-autocomplete.css";
/* Custom widgets css files - ended */

import moment from "moment";
import ConstructionPage from "./ConstructionPage";
import { customQuestionRendering, customResponsePreparing } from "../customQuestions/CustomQuestionRendering"

const defaultcampaignimage = require("../images/background.jpg").default;
const defaultlogo = require("../images/civicainvolvelogo.png").default;
let currentPageIndex = 0; // track what page the user is visible on survey

class SurveyPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      foundSurvey: false,
      isCompleted: false,
      json: {},
      tracklocationresult: {},
      campaignImage: defaultcampaignimage, // default background image
      campaignPrimaryColor: '#009ca6', // default primary color
      //campaignPrimaryColorHover: '#006d74', // default primary color on hover // doing programatically based on primary color
      campaignSecondaryColor: '#d62598', // default secondary color
      campaignBgMask: '60%', // how dark is the mask on the bg image -- 100% is normal, 0% would be totally blacked out.  suggest 85%
      campaignLogo: defaultlogo, // default logo,
      campaignLogoPosition: "right",
      campaignJustification: 'center',
      campaignWidth: '80',
      campaignUseBgColor: false,
      campaignBgColor: 'white',
      campaignTextColor: 'black',
      campaignCardColor: 'white',
      campaignCardShadow: true,
      campaignPrivacyPolicy: null,
      campaignCookiePolicy: null,
      detectedBgColors: [],
      customerdefaultcampaignprimarycolor: '#009ca6',
      customerdefaultcampaignsecondarycolor: '#d62598',
      customerdefaultbgversion: null,
      customerdefaultlogoversion: null,
      campaignId: null,
      userId: null,
      testMode: false,
      fingerprint: null,
      sessionId: null,
      singlePageMode: false,
      forceResponseReview: false,
      forceLocale: null,
      selectLanguage: false,
      ipAddr: null,
      newGroupQuestion: [],
      highContrastMode: false,
      surveyStartedAt: null,
      utmParams: {},
      externalUserId : null
    };
    this.onCompleteComponent = this.onCompleteComponent.bind(this);
    this.uploadFileToBlob = this.uploadFileToBlob.bind(this)
    this.downloadFileFromBlob = this.downloadFileFromBlob.bind(this)
    this.customQuestionRendering = customQuestionRendering.bind(this);
    this.customResponsePreparing = customResponsePreparing.bind(this);
  }

  componentDidMount() {
    // this stuff DOES seem to run
    console.log("componentDidMount fired");

    console.log(this.props);

    console.log("getting settings from queryparams");

    // valid things to pass in: userid, testmode, singlepage, forceresponsereview, forcelocale, selectlanguage

    const params = new URLSearchParams(window.location.search);

    // userid
    const userid = params.get("userid");
    console.log('userid: ', userid);
   
    // test mode is like nocommmit
    const testmode = params.get("testmode");
    console.log('testmode: ', testmode);

    // single page mode
    const singlepagemode = params.get("singlepage") !== null ? this.stringToBoolean(params.get("singlepage")) : null;
    console.log('singlepagemode: ', singlepagemode);

    // force response review before submitting
    const forceresponsereview = params.get("forceresponsereview") !== null ? this.stringToBoolean(params.get("forceresponsereview")) : null;
    console.log('forceresponsereview: ', forceresponsereview);

    // force a language
    const forcelocale = params.get("forcelocale") !== null ? params.get("forcelocale").toLowerCase() : null;
    console.log('forcelocale: ', forcelocale);

    // force language seleection
    const selectlanguage = params.get("selectlanguage") !== null ? this.stringToBoolean(params.get("selectlanguage")) : null;
    console.log('selectlanguage: ', selectlanguage);

    //high contrast mode
    const highcontrastmode = params.get("highcontrastmode") !== null ? this.stringToBoolean(params.get("highcontrastmode")) : null;
    console.log('highcontrastmode: ', highcontrastmode);

    //externaluserid
    const externalUserId = params.get("externaluserid");
    console.log('externalUserId: ', externalUserId);

    // utm params?
    let utmparams = {};
    for (const [key, value] of params) {
      if(key.startsWith("utm_")){
        utmparams[key] = value;
      }
    }

    this.setState({ userId: userid, testMode: testmode, sessionId: uuid(), singlePageMode: singlepagemode, forceResponseReview: forceresponsereview, forceLocale: forcelocale, selectLanguage: selectlanguage, utmParams: utmparams, externalUserId : externalUserId });

    // get user fingerprint
    this.getFingerprint();

    let campaignId = this.props.match.params.campaignId;
    this.setState({ campaignId: campaignId });
    if (campaignId) {
      console.log("adding question to the survey jsonobject");
      Survey.JsonObject.metaData.addProperty("question", {
        name: "quid",
        type: "string"
      });

      Survey.JsonObject.metaData.removeProperty("file", "storeDataAsText");
      Survey.JsonObject.metaData.addProperty("file", { name: "storeDataAsText:boolean", default: false, visible: false }); // hide the option to store as json

      console.log("API call for getCampaignDetails");
      fetch(`${process.env.REACT_APP_API_ENDPOINT}getCampaignDetails`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          "campaignId": campaignId, //globally unique campaignId so no need of customerId combinations
          "userId" : userid
        })
      })
        .then(response => response.json())
        .then(data => {
          console.log("#18");
          console.log('getCampaignDetails response: ', data);
          if (data.response) {
            console.log('made it through if statement');
            // i think, but am not entirely sure -- it might be that certain question types that we created need to have support brought in in here -- i.e. datepicker, tagbox, editor - aren't coming through in one of the samples i've set up, and we may need to look importing support for those question types here as well - they may not be supported in the default package.

            // as a fallback, we should probably also grab the customer settings, as they may have a default background or color scheme to apply
            console.log("API call for getCustomerSurveyDefaultsNPY");
            fetch(`${process.env.REACT_APP_API_ENDPOINT}getCustomerSurveyDefaultsNPY`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                "campaignId": campaignId, //globally unique campaignId so no need of customerId combinations
              })
            })
              .then(response2 => response2.json())
              .then(data2 => {
                console.log("#18 inner");
                console.log('getCustomerSurveyDefaultsNPY response: ', data2);
                if (data2.results) {
                  console.log('made it through second if statement');
                  // i think, but am not entirely sure -- it might be that certain question types that we created need to have support brought in in here -- i.e. datepicker, tagbox, editor - aren't coming through in one of the samples i've set up, and we may need to look importing support for those question types here as well - they may not be supported in the default package.

                  console.log('setting the state, customerid is: ', data.response.customerid);
                  data.response = this.customQuestionRendering(data.response);

                  console.log('here is data.response after calling customQuestionRendering');
                  console.log(data.response);

                  if (highcontrastmode) {
                    this.setState({
                      campaignTextColor: 'black',
                      campaignImage: highcontrastmode ? '' : defaultcampaignimage,
                      campaignBgColor: 'white',
                    });
                  } else {
                    this.setState({
                      campaignTextColor: data.response.hasOwnProperty('campaignUseTextColor') && data.response.campaignUseTextColor ? data.response.campaignTextColor : 'black',
                      campaignImage: data.response.hasOwnProperty('campaignBackground') ? data.response.campaignBackground : defaultcampaignimage,
                      campaignBgColor: data.response.hasOwnProperty('campaignUseBgColor') && data.response.campaignUseBgColor ? data.response.campaignBgColor : 'white',
                    });
                  }


                  this.setState({
                    foundSurvey: true,
                    json: data.response,
                    // HARDCODED CAMPAIGN DATA
                    campaignPrimaryColor: (data.response.hasOwnProperty('primaryColor') && this.isValidHexColor(data.response.primaryColor)) ? data.response.primaryColor : '#009ca6', // edit primary color here
                    //campaignPrimaryColorHover: data.response.hasOwnProperty('primaryColorHover') ? data.response.primaryColorHover :'#006d74', // edit primary color on hover here // doing programatically based on primary color
                    campaignSecondaryColor: (data.response.hasOwnProperty('secondaryColor') && this.isValidHexColor(data.response.secondaryColor)) ? data.response.secondaryColor : '#d62598', // edit secondary color here
                    campaignBgMask: data.response.hasOwnProperty('bgMask') ? data.response.bgMask : '60%', // how dark is the mask on the bg image -- 100% is normal, 0% would be totally blacked out.  suggest 85%
                    campaignLogo: data.response.hasOwnProperty('campaignLogo') ? data.response.campaignLogo : defaultlogo, // edit logo here
                    campaignLogoPosition: data.response.hasOwnProperty('campaignLogoPosition') ? data.response.campaignLogoPosition : "right",
                    campaignJustification: data.response.hasOwnProperty('justification') ? data.response.justification : 'center', // edit vertical layout here ('center', 'top')
                    campaignWidth: data.response.hasOwnProperty('width') ? data.response.width : '80',
                    campaignUseBgColor: data.response.hasOwnProperty('campaignUseBgColor') ? data.response.campaignUseBgColor : false,
                    campaignCardColor: data.response.hasOwnProperty('campaignUseCardColor') && data.response.campaignCardColor ? data.response.campaignCardColor : 'white',
                    campaignCardShadow: data.response.hasOwnProperty('campaignShowShadow') ? data.response.campaignShowShadow : false,
                    campaignPrivacyPolicy: data.response.hasOwnProperty('campaignPrivacyPolicy') ? data.response.campaignPrivacyPolicy : null,
                    campaignCookiePolicy: data.response.hasOwnProperty('campaignCookiePolicy') ? data.response.campaignCookiePolicy : null,
                    customerId: data.response.customerid,
                    // tracklocation: data.response.hasOwnProperty('tracklocation') ? data.response.tracklocation : false,

                    customerdefaultcampaignprimarycolor: data2.results.defaultcampaignprimarycolor,
                    customerdefaultcampaignsecondarycolor: data2.results.defaultcampaignsecondarycolor,
                    customerdefaultbgversion: data2.results.defaultlogoversion,
                    customerdefaultlogoversion: data2.results.defaultlogoversion,
                    highContrastMode: highcontrastmode,

                    // can we grab their ip?
                    ipAddr: data.response.hasOwnProperty('ipAddr') ? data.response.ipAddr : null,

                    // HARDCODED CAMPAIGN DATA
                    loading: false // turn off loader
                  });


                  // did we have geolocation on?
                  const trackLocation = data.response.hasOwnProperty('tracklocation') ? data.response.tracklocation : false
                  console.log('are we supposed to track location? ', trackLocation);
                  if (trackLocation) {
                    console.log('location requested, will see if we can grab it');

                    if ("geolocation" in navigator) {
                      console.log("geolocation Available");

                      navigator.geolocation.getCurrentPosition(position => {
                        console.log("Latitude is :", position.coords.latitude);
                        console.log("Longitude is :", position.coords.longitude);

                        this.setState({ tracklocationresult: position })
                      });
                    } else {
                      console.log("geolocation Not Available");
                    }
                  }

                  // retrieve new user cookie
                  let newUser = localStorage.getItem('newUser');
                  if (newUser === null) {
                    console.log('new user detected, showing cookie consent');
                    // trigger toast and set newUser cookie to false
                    this.toastify(); // cookie consent toastify
                    localStorage.setItem('newUser', false);
                  }

                  // the title/description html that the survey provides is not good
                  // needed a custom function to remove their html and insert our own for styling purposes
                  // renderCustomTitleBlock checks the page index and inserts an appropriate title/description
                  // will also hide our custom element if no values exist for the page


                  // before we run renderCustomTitleBlock
                  // we need to initially determine if we have title/description
                  // and hide accordinlgly
                  const title = (data.response.pages[0].hasOwnProperty('title')) ? data.response.pages[0].title : null;
                  const description = (data.response.pages[0].hasOwnProperty('description')) ? data.response.pages[0].description : null;

                  if (title) $('.sv_page_title').hide();
                  // hide the old description        
                  if (title && description) $('.sv_page_title').next().hide();
                  setTimeout(() => {
                    this.renderCustomTitleBlock(currentPageIndex);
                  }, 50);

                } else {
                  this.setState({
                    loading: false,
                    foundSurvey: false
                  })
                }
              });

          } else {
            this.setState({
              loading: false,
              foundSurvey: false
            })
          }
        });
    }
  }

  componentWillMount() {
    // this does happen
    console.log("component will mount fired");
  }

  onCompleteComponent(surveyObj, options) {
    console.log("onCompleteComponent fired");

    const { tracklocationresult, testMode, sessionId, userId, fingerprint, ipAddr } = this.state; // bring state variables in

    let resultData = [];
    let returnObj = {};
    let cxApiValues = [];
    for (let key in surveyObj.data) {
      let question = surveyObj.getQuestionByValueName(key);
      if (!!question) {
        let item = { value: question.value };
        // is it a signaturepad type?
        if (question.getType() === 'signaturepad') {
          console.log("found a signaturepad type!! let's do something about that");
          let base64string = question.value;

          let signaturefilename = uuid();
          let signatureazurename = `${signaturefilename}.png`;

          const newurl = `${process.env.REACT_APP_AZURE_BLOB_URL}/${process.env.REACT_APP_AZURE_BLOB_CONTAINER}/${signatureazurename}`;

          console.log(`here's how it starts: ${base64string.substring(0, 50)}`);

          // now we need to pass it off
          this.uploadBase64ToBlob(base64string, newurl, signaturefilename, 'png');

          console.log(`updating the response value to be the url ${newurl}`);
          item.value = newurl;
          question.value = newurl;
        }

        //If question name (question.valueName) doesn't equal to question.title
        if (key !== question.title) {
          item.title = question.title;
        }
        //If question value different from displayValue
        if (key.value != question.displayValue) {
          console.log('key value and question displayValue mismatch, updating?')
          item.displayValue = question.displayValue
        }
        //If the custom property tag is defined set
        if (question.quid !== undefined) {
          item.quid = question.quid;
        }
        resultData.push(item);
        returnObj[item.quid] = question.value;

        cxApiValues.push({
          "Guid": item.quid,
          "QuestionTitle": question.title,
          "Value": item.displayValue
        });
        // ticket #51312 - when a user writes in a comment in an 'other' field, it's not currently captured
        // it's tucked into item.comment
        console.log('question.propertyHash.comment: ', question.propertyHash.comment);

        if (question.propertyHash.hasOwnProperty('comment') && question.propertyHash.comment != '') {
          console.log('response had something written in?');

          // check if we have an object already to collect these things?
          if (returnObj.hasOwnProperty('userOtherInput')) {
            console.log("response object did already have userOtherInput, just add this value");
            returnObj.userOtherInput[item.quid] = question.propertyHash.comment;

          } else {
            console.log("response had no other user specified input, setting up the object to capture it");
            returnObj.userOtherInput = {};

            // now put this one in there
            returnObj.userOtherInput[item.quid] = question.propertyHash.comment;
          }
        }

      }
    }

    returnObj.locationdata = tracklocationresult;
    returnObj.ipaddress = ipAddr;

    // timing information
    returnObj.surveystarttime = this.state.surveyStartedAt;

    const finishedtime = moment().utc();
    returnObj.surveyfinishtime = finishedtime;
    returnObj.surveyduration = finishedtime.diff(this.state.surveyStartedAt);

    returnObj.utmparams = this.state.utmParams;
    returnObj = this.customResponsePreparing(resultData, returnObj);
    // we don't actually use this resultData array for anything?  right? other than an input to this function?
    //store survey response
    let campaignId = this.props.match.params.campaignId;

    // will need to get customerid on the fly
    // should use device fingerprint for userid if we don't have a userid passed in via param

    console.log('did we have a userid?: ', userId);
    console.log('fingerprint: ', fingerprint);
    console.log('sessionId: ', sessionId);

    let user = userId;
    if (!user) {
      console.log("no userId, using device fingerprint");
      user = fingerprint;
    }

    // just using uuid for now so we aren't just constantly updating one response...
    
    const jsonBody = {
      "campaignId": campaignId, //globally unique campaignId so no need of customerId combinations
      //"customerId": process.env.REACT_APP_CUSTOMERID || "ntpylocCus1",
      "userId": user,
      "sessionId": sessionId,
      "surveyObj": returnObj,
      "cxValues" : cxApiValues,
      "externalUserId" : this.state.externalUserId || null
    }

    // if we're in test mode, no need to send the response off
    if (testMode) {
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> TEST MODE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      console.log('no response submitted to the api.  here is what would have been sent');
      console.log(jsonBody);

    } else {
      console.log('submitting the response to the api');

      fetch(`${process.env.REACT_APP_API_ENDPOINT}storeSurveyResponse`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(jsonBody)
      })
        .then(response => response.json())
        .then(data => {
          console.log("#86");
          console.log(data);
        });
    }
  }

  onStartedComponent(surveyObj) {
    // this stuff never seems to fire?
    console.log("onStartedComponent -- adding custom property quid to questions");
    Survey.Serializer.addProperties("question", {
      name: "quid"
    });

    Survey.Serializer.removeProperty("file", "storeDataAsText");
    Survey.Serializer.addProperty("file", { name: "storeDataAsText:boolean", default: false, visible: false }); // hide the option to store as json

  }

  // fires anytime a page is changed on survey
  onCurrentPageChanged = (e, value) => {
    console.log('onCurrentPageChanged fired: ', e);
    console.log('value: ', value);
    console.log('title: ', value.newCurrentPage.title); /// <--- works
    console.log('description: ', value.newCurrentPage.description); /// <--- works??
    console.log('has description: ', value.newCurrentPage.hasDescription);
    console.log('get description by name: ', value.newCurrentPage.getPropertyByName('description'));

    // need to update our titleBlock
    this.renderCustomTitleBlock(value.newCurrentPage.title, value.newCurrentPage.description);
  }

  // the title/description html that the survey provides is not good
  // needed a custom function to remove their html and insert our own for styling purposes
  // renderCustomTitleBlock checks the page index and inserts an appropriate title/description
  // will also hide our custom element if no values exist for the page
  renderCustomTitleBlock(title, description) {
    console.log('renderCustomTitleBlock fired for title, description: ', title, description);

    // if we have a title or description, we need to do some things
    if (title || description) {
      console.log(`found a title: ${title} or description: ${description} for this page`);

      // first check if don't have a titleBlock
      if (!$('#titleBlock').length) {
        console.log('no titleBlock found, creating one');
        // we don't have a titleBlock, so create one       
        const titleBlock = $(`<div id="titleBlock" class="titleBlock"></div>`);
        // append it to the survey
        $('.sv_p_root').prepend(titleBlock);
      }

      // check if we have a description
      if (description) {
        // next check if we have already appended our description element
        if (!$('#titleBlock-description').length) {
          console.log('no description element found, creating one');
          const descriptionElement = $(`<div id="titleBlock-description"><span></span></div>`);
          $('#titleBlock').append(descriptionElement);
        }
        // update the title element's text
        $('#titleBlock-description').text(description);
      } else {
        // update the title element's text blank because no description for this page
        $('#titleBlock-description').text('');
      }

      // check if we have a title
      if (title) {
        // next check if we have already appended our title element
        if (!$('#titleBlock-title').length) {
          console.log('no title element found, creating one');
          const titleElement = $(`<h4 id="titleBlock-title"><span></span></h4>`);
          $('#titleBlock').prepend(titleElement);
        }
        // update the title element's text
        $('#titleBlock-title').text(title);
      } else {
        // update the title element's text blank because no title for this page
        $('#titleBlock-title').text('');
      }

      setTimeout(() => {
        // lastly hide the old title
        $('.sv_page_title').hide();
        // hide the old description        
        if (title && description) $('.sv_page_title').next().hide();
        if (!title && description) $('#titleBlock').next().hide();
        // display our custom titleBlock element
        $('#titleBlock').show();
      }, 50)

    }
    // no title or description, let's hide everything
    else {
      console.log('page was either missing elements, title, or description. Hiding title block elements');
      $('.sv_page_title').show();
      $('#sv_page_title').text('');
      $('#sv_page_description').text('');
      $('#titleBlock').hide();
    }

  }

  dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = decodeURI(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    let ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }

  uploadBase64ToBlob(base64string, targeturl, filename, ext) {
    console.log("uploadBase64ToBlob called");

    const { customerId, userId, fingerprint, campaignId } = this.state;
    console.log('initial customerId at function init: ', customerId);

    console.log('customerId in options callback: ', customerId);

    let file = this.dataURItoBlob(base64string);

    console.log("ok, parsed base64 image to file, continue");

    let user = userId;

    if (!user) {
      console.log("didn't have a userid, using device fingerprint");
      user = fingerprint;
    }

    console.log("userid to send in with file upload:", user);

    if (customerId) {

      let form_data = new FormData();
      form_data.append("customerid", customerId);
      form_data.append("campaignid", campaignId);
      form_data.append("name", filename);
      form_data.append("azurefilename", filename);
      form_data.append("type", ext);
      form_data.append("userid", user);
      form_data.append("file", file);

      console.log('form_data: ', form_data);
      console.log('customerid: ', customerId);
      console.log('campaignid: ', campaignId);
      console.log('name: ', filename);
      console.log('azurefilename: ', filename);
      console.log('type: ', ext);
      console.log('userid: ', user);
      console.log('file: ', file);

      console.log("making ajax call");
      $.ajax({
        url: `${process.env.REACT_APP_API_ENDPOINT}uploadDocument`, // https://surveyjs.io/api/MySurveys/uploadFiles
        type: "POST",
        xhr: function () {
          let myXhr = $.ajaxSettings.xhr();
          if (myXhr.upload) {
            myXhr.upload.addEventListener('progress', function (event) {}, false);
          }
          return myXhr;
        },
        success: function (data) {
          console.log("ajax success, calling options callback");
          console.log('data: ', data);
          console.log(`all done uploading base64 string as file to blob -- should now be available at ${targeturl}`);
        },
        error: function (error) {
          console.log("ajax error uploading document. ", error);
        },
        async: true,
        data: form_data,
        cache: false,
        contentType: false,
        processData: false,
        timeout: 60000
      });
    }
  }

  uploadFileToBlob = (survey, options) => {
    console.log("uploadFileToBlob called");
    console.log('upload survey', survey);
    console.log('upload options', options);

    const { customerId, userId, fingerprint, campaignId } = this.state;
    console.log('initial customerId at function init: ', customerId);

    options.files.forEach((thisfile) => {

      console.log('customerId in options callback: ', customerId);

      let file = thisfile;
      let filename = thisfile.name;
      let azurefilename = uuid();
      let dot = filename.lastIndexOf(".");
      let ext = filename.substring(dot + 1, filename.length);

      let fullazurename = `${azurefilename}.${ext}`;

      let user = userId;

      if (!user) {
        console.log("didn't have a userid, using device fingerprint");
        user = fingerprint;
      }

      console.log("userid to send in with file upload:", user);

      if (customerId) {

        let form_data = new FormData();
        form_data.append("customerid", customerId);
        form_data.append("campaignid", campaignId);
        form_data.append("name", filename);
        form_data.append("azurefilename", azurefilename);
        form_data.append("type", ext);
        form_data.append("userid", user);
        form_data.append("file", file);

        console.log('form_data: ', form_data);
        console.log('customerid: ', customerId);
        console.log('campaignid: ', campaignId);
        console.log('name: ', filename);
        console.log('azurefilename: ', fullazurename);
        console.log('type: ', ext);
        console.log('userid: ', user);
        console.log('file: ', file);

        console.log("making ajax call");
        $.ajax({
          url: `${process.env.REACT_APP_API_ENDPOINT}uploadDocument`, // https://surveyjs.io/api/MySurveys/uploadFiles
          type: "POST",
          xhr: function () {
            let myXhr = $.ajaxSettings.xhr();
            if (myXhr.upload) {
              myXhr.upload.addEventListener('progress', function (event) {}, false);
            }
            return myXhr;
          },
          success: function (data) {
            console.log("ajax success, calling options callback");
            console.log('data: ', data);

            options.callback("success",
              options.files.map(function (file) {
                console.log("ajax success options callback, ready to return file and content");
                console.log('ajax success options callback file: ', file);
                console.log('ajax success options callback fullazurename: ', fullazurename);

                const newurl = `${process.env.REACT_APP_AZURE_BLOB_URL}/${process.env.REACT_APP_AZURE_BLOB_CONTAINER}/${fullazurename}`;

                console.log(`resetting conent to  be the newurl: ${newurl}`);

                return { file: file, content: newurl };
              })
            );
          },
          error: function (error) {
            console.log("ajax error uploading document. ", error);
          },
          async: true,
          data: form_data,
          cache: false,
          contentType: false,
          processData: false,
          timeout: 60000
        });
      }
    });
  }

  downloadFileFromBlob = (survey, options) => {
    console.log("downloadFileFromBlob called.");
    console.log('download survey', survey);
    console.log('download options', options);

    let xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.open("GET", `${process.env.REACT_APP_AZURE_BLOB_URL}/${options.fullazurename}`); // "https://surveyjs.io/api/MySurveys/files?name=" + options.content
    xhr.onload = function () {
      let reader = new FileReader();
      reader.onload = function (e) {
        options.callback("success", e.target.result);
      };
      reader.readAsDataURL(
        new File([xhr.response], options.fileValue.name, { type: options.fileValue.type })
      );
    };
    xhr.send();

  };

  getFingerprint() {

    if (root.requestIdleCallback) {
      requestIdleCallback(() => {
        Fingerprint2.get((components) => {
          let fingerprint = Fingerprint2.x64hash128(components.map(function (pair) { return pair.value }).join(), 31)
          this.setState({ fingerprint: fingerprint });
        })
      })
    } else {
      setTimeout(() => {
        Fingerprint2.get((components) => {
          let fingerprint = Fingerprint2.x64hash128(components.map(function (pair) { return pair.value }).join(), 31)
          //GAFingerprint = fingerprint;
          //store.dispatch(storeFingerprint(fingerprint));
          this.setState({ fingerprint: fingerprint });
        })
      }, 500)
    }
  }

  // check if string is valid hex color (returns true/false)
  isValidHexColor = (color) => {
    const reg = /^#([0-9a-f]{3}){1,2}$/i;
    return reg.test(color);
  }
  shadeHexColor(color, percent) {
    console.log('shadeHexColor fired for color: ', color);
    let f = parseInt(color.slice(1), 16), t = percent < 0 ? 0 : 255, p = percent < 0 ? percent * -1 : percent, R = f >> 16, G = f >> 8 & 0x00FF, B = f & 0x0000FF;
    return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
  }

  convertBrightnessToInversePercent(inputnumber) {
    console.log(`converting brightness to inverse percent -- ${inputnumber}`);

    switch (inputnumber) {
      case inputnumber >= 100:
        return 0;
      case inputnumber <= 0:
        return 1;
      default:
        return (100 - inputnumber) / 100;
    }

  }

  stringToBoolean(string) {
    switch (string.toLowerCase().trim()) {
      case "true": case "yes": case "1": return true;
      case "false": case "no": case "0": case null: return false;
      default: return Boolean(false);
    }
  }

  convertBrightnessToPercent(inputnumber) {
    console.log(`converting brightness to precent -- ${inputnumber}`);

    switch (inputnumber) {
      case inputnumber >= 100:
        return 1;
      case inputnumber <= 0:
        return 0;
      default:
        return (inputnumber / 100);
    }

  }

  setActiveLocale(locale) {
    if (locale == null) {
      console.log("user selected default locale");
      this.setState({ selectLanguage: false, forceLocale: null });
    } else {
      console.log("setting the user's active locale / language: ", locale);
      this.setState({ selectLanguage: false, forceLocale: locale });
    }

  }

  toastify = () => {
    const { campaignPrivacyPolicy, campaignCookiePolicy } = this.state;
    console.log('toastify called');
    let privacyPolicy = "/privacy-policy";
    if (campaignPrivacyPolicy) privacyPolicy = campaignPrivacyPolicy;

    let cookiePolicy = "/cookie-policy";
    if (campaignCookiePolicy) privacyPolicy = campaignCookiePolicy;

    const message = () => (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FaInfoCircle style={{ fontSize: '42px', color: 'rgb(8, 166, 214)' }} />
        <p style={{ margin: '0 10px' }}>This website uses cookies to provide necessary website functionality, improve your experience and analyze our traffic. By using our website, you agree to our <a href={privacyPolicy} target="_blank" rel="noopener noreferrer">privacy policy</a> and our <a href={cookiePolicy} target="_blank" rel="noopener noreferrer">cookies usage.</a></p>
      </div>
    );

    toast(message, {
      containerId: "cookieConsent",
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: false,
      delay: 1000,
      className: css({
        minWidth: 'auto !important', 
        height: 'auto !important', 
        '@media screen and (min-width: 801px)': {
          fontSize: '1rem',
          textAlign: 'center',
          height: 'auto !important',
          minWidth: 'auto !important'
        },
        '@media screen and (min-width: 480px)': {
          fontSize: '1rem',
          textAlign: 'center',
          height: 'auto !important',
          minWidth: 'auto !important'
        },
        '@media screen and (min-width: 320px)': {
          fontSize: '1rem',
          textAlign: 'center',
          height: 'auto !important',
          minWidth: 'auto !important'
        }
      }),
      bodyClassName: css({
        fontSize: '14px',
        color: 'rgba(0,0,0,0.8)',
        padding: '0 10px'
      })
    })
  }

  render() {
    console.log("render fired");

    const { loading, foundSurvey, campaignImage, campaignPrimaryColor, campaignLogo, campaignLogoPosition, campaignJustification, campaignWidth, campaignUseBgColor, campaignBgColor, campaignBgMask, campaignTextColor, campaignCardColor, campaignCardShadow, customerdefaultcampaignprimarycolor, customerdefaultcampaignsecondarycolor, customerdefaultlogoversion, customerdefaultbgversion, singlePageMode, forceResponseReview, forceLocale, selectLanguage, ipAddr } = this.state; // bring state variables in

    console.log('campaignCardColor in render: ', campaignCardColor);
    window["$"] = window["jQuery"] = $;

    // check if we're done loading
    if (!loading) {
      // check if a survey was actually found in api response
      if (foundSurvey) {

        // need to check to make sure that the survey is active, and that it's in the window where it should be live
        // so, check surveyIsActive -- that's the manual override
        // also, need to check the start and the end -- if we have start and now is before start, don't show. start is just .startdate, end is just .enddate
        // if we have end and now is after end, don't show
        // 		surveydata.tooearlymsg;
        //    surveydata.toolatemsg;
        //    surveydata.offlinemsg;

        let unavailable = false;
        let unavailableReason = 'offline';
        let unavailableMsg = 'the survey is offline';

        console.log(`the time is now ${moment().utc()}`);
        console.log('surveyIsActive? : ', this.state.json.surveyIsActive);
        console.log('offlinemsg? : ', this.state.json.offlinemsg);
        console.log('has start? : ', this.state.json.hasStartTime);
        console.log('start? : ', this.state.json.startdate);
        console.log('tooearlymsg? : ', this.state.json.tooearlymsg);
        console.log('has end? : ', this.state.json.hasEndTime);
        console.log('end? : ', this.state.json.enddate);
        console.log('toolatemsg? : ', this.state.json.toolatemsg);

        switch (true) {
          case this.state.json.hasOwnProperty('surveyIsActive') && !this.state.json.surveyIsActive:
            unavailable = true;
            unavailableMsg = this.state.json.offlinemsg ? this.state.json.offlinemsg : '';
            break;

          case this.state.json.hasOwnProperty('hasStartTime') && this.state.json.hasStartTime && this.state.json.hasOwnProperty('startdate') && moment().isBefore(moment(this.state.json.startdate)):
            unavailable = true;
            unavailableReason = 'tooearly';
            unavailableMsg = this.state.json.tooearlymsg ? this.state.json.tooearlymsg : '';
            break;

          case this.state.json.hasOwnProperty('hasEndTime') && this.state.json.hasEndTime && this.state.json.hasOwnProperty('enddate') && moment().isAfter(moment(this.state.json.enddate)):
            unavailable = true;
            unavailableReason = 'toolate';
            unavailableMsg = this.state.json.toolatemsg ? this.state.json.toolatemsg : '';
            break;
        }

        if (unavailable) {
          // UnavailablePage
          console.log("survey was unavailable, showing the unavailable page");
          console.log("unavilable reason? ", unavailableReason);

          return (
            <UnavailablePage reason={unavailableReason} message={unavailableMsg} />
          )
        } else {

          if (this.state.json.hasOwnProperty('enabledLanguages')) {
            console.log(`this campaign has ${this.state.json.enabledLanguages.length} languages enabled`);
          } else {
            console.log("this campaign does not have any languages enabled");
          }

          // did we want to force them to select a language?
          if (selectLanguage && this.state.json.hasOwnProperty('enabledLanguages') && this.state.json.enabledLanguages.length > 0) {
            console.log("language selection was enabled, and so were some langauges, forcing user to select a language");

            let bgstyle = { position: 'relative' };
            let maskstyle = { position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' };

            let favicon = require("../images/civica_favicon-32x32.ico").default;

            console.log('campaignUseBgColor: ', campaignUseBgColor);

            if (campaignUseBgColor) {
              console.log("campaign is set to use bg color");

              const masksetting = this.convertBrightnessToInversePercent(campaignBgMask);

              console.log(`campaignBgColor: ${campaignBgColor}`);
              console.log(`masksetting: ${masksetting}`);

              bgstyle.backgroundColor = campaignBgColor;
              maskstyle.backgroundColor = `rgba(0, 0, 0, ${masksetting})`;
            } else {
              console.log("campaign should show bg image");

              const imagemasksetting = this.convertBrightnessToPercent(campaignBgMask);

              console.log(`bgimage: ${campaignImage}`);
              console.log(`imagemasksetting: ${imagemasksetting}`);

              bgstyle.backgroundImage = `url(${campaignImage})`;
              maskstyle.backdropFilter = `brightness(${imagemasksetting})`;
            }

            return (
              <>
                <Helmet>
                  <meta charSet="utf-8" />
                  {typeof(this.state.json.title) == "object"?<title>{this.state.json.title.default}</title>: <title>{this.state.json.title}</title>}
                  <link rel="shortcut icon" href={favicon} />
                  <link rel="apple-touch-icon" href={favicon} />
                </Helmet>
                <div className={`${campaignJustification === 'center' ? 'page-centered' : 'page'} ${campaignCardShadow ? 'cardshadow' : 'nocardshadow'}`} style={bgstyle}>
                  <div style={maskstyle}></div>
                  <img className={`logo logo-${campaignLogoPosition.toLowerCase()}`} src={campaignLogo} />
                  <div className="language-wrapper" style={{ width: `${(campaignWidth < 40) ? '40' : campaignWidth}%` }}>
                    <div className="sv_row" style={{ fontSize: '36px'}}>
                      Please select a language:

                      {this.state.json.enabledLanguages.map(e => (
                        <div key={e.id} className="langbuttondiv"><button className="langbutton" onClick={() => this.setActiveLocale(e.id)}>{e.label}</button></div>
                      ))}

                      <div className="langbuttondiv"><button className="langbutton" onClick={() => this.setActiveLocale(null)}>The default language is fine.</button></div>
                    </div>
                  </div>
                </div>
              </>
            );

          } else {
            console.log("survey was found and is online, and no language selection was required (or it was already done), render ready to return an actual survey component");

            console.log("#108 this.props");
            console.log(this.props);

            console.log("survey json: ", this.state.json);

            console.log("doing survey serializer things");
            Survey.Serializer.removeProperty("file", "storeDataAsText");
            // when you set this to true, it will let you pick a file
            // setting it to false, which is what we want, it doesn't seem to let you pick a file -- i'm guessing unless it knows how to upload it, but i have no idea what the hell is going on
            Survey.Serializer.addProperty("file", { name: "storeDataAsText:boolean", default: false, visible: false }); // hide the option to store as json

            console.log(' |||||||||||||||||||||| >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> sanity check output:');
            console.log({ loading, foundSurvey, campaignImage, campaignPrimaryColor, campaignLogo, campaignJustification, campaignWidth, campaignUseBgColor, campaignBgColor, campaignBgMask, campaignTextColor, campaignCardColor, campaignCardShadow, customerdefaultcampaignprimarycolor, customerdefaultcampaignsecondarycolor, customerdefaultlogoversion, customerdefaultbgversion, ipAddr });

            console.log("initializing custom widgets");
            autocomplete(Survey);
            jqueryuidatepicker(Survey);
            nouislider(Survey);
            sortablejs(Survey);
            icheck(Survey);
            select2(Survey);
            inputmask(Survey);
            jquerybarrating(Survey);
            select2tagbox(Survey);
            prettycheckbox(Survey);
            bootstrapslider(Survey);
            microphone(Survey);
            emotionsratings(Survey);
            bootstrapdatepicker(Survey);


            // eventually we'll probably need the modern theme, as it seems to include a few things that we need?  especially for the single input with type = date, but it wasn't working fully anyhow, and it doesn't look great until we update the other css-theme stuff with modern, so i'm flipping it back for now

            // set theme colors for survey
            console.log("setting themes and such via survey styles manager");

            let defaultThemeColors = Survey.StylesManager.ThemeColors["default"];

            console.log('campaignPrimaryColor: ', campaignPrimaryColor);
            const hovercolor = this.shadeHexColor(campaignPrimaryColor, -0.25);
            console.log('hovercolor: ', hovercolor);

            console.log('campaignCardColor: ', campaignCardColor);
            const bordercolor = this.shadeHexColor(campaignCardColor, -0.25);
            console.log('bordercolor: ', bordercolor);

            console.log('defaultThemeColors: ', defaultThemeColors);
            defaultThemeColors["$main-color"] = campaignPrimaryColor;
            defaultThemeColors["$main-hover-color"] = hovercolor; // will do programatically based on primary color
            defaultThemeColors["$text-color"] = campaignTextColor;
            defaultThemeColors["$header-color"] = campaignPrimaryColor;
            defaultThemeColors["$header-background-color"] = "#4a4a4a";
            defaultThemeColors["$body-container-background-color"] = "#f8f8f8";
            defaultThemeColors["$body-background-color"] = campaignCardColor;
            defaultThemeColors["$border-color"] = bordercolor;
            // we can define custom classes for survey.js
            const customCSS = {
              ranking: { root: 'ranking-custom' }, // this one works
              sortablelist: { root: "sortable-list-custom" } // this one doesnt. unclear what the fieldname variable for sortablelist is
            };
            // convert our #hexcolor into rgb (so that we can adjust opacity for it with rgba)
            let r = convertColorToRGB(campaignPrimaryColor).r;
            let g = convertColorToRGB(campaignPrimaryColor).g;
            let b = convertColorToRGB(campaignPrimaryColor).b;
            // create a new style element
            const $style = document.createElement("style");
            // append the style element to the document head
            document.head.appendChild($style);
            // set the style element with whatever custom styles we want
            // we can target any specific classes or element here with our campaign colors etc.
            $style.innerHTML = `
              .ranking-custom svg { fill: ${campaignPrimaryColor}; }
              .ranking-custom .sv-ranking-item__index { background: rgba(${r},${g},${b},0.1); }
              .sjs-sortablejs-item { background-color: ${campaignPrimaryColor}!important; }
              .sjs-sortablejs-result { border: 1px solid ${campaignPrimaryColor}!important; }
              .sjs-sortablejs-source { border: 1px solid ${campaignPrimaryColor}!important; }
              .sv_row .sv_row { background-color: ${campaignCardColor}!important; }
              .sv_main.sv_default_css .sv_p_root > .sv_row:nth-child(2n) { background-color: ${campaignCardColor}!important; }
              .noUi-handle { background-color: ${campaignPrimaryColor}!important; }
              .noUi-connect { background-color: ${lightenDarkenColor(campaignPrimaryColor,150)}!important; }
              .slider-handle { background-color:  ${campaignPrimaryColor}!important; background-image: unset!important; }
              .slider-selection {
                background-image: linear-gradient(to bottom, ${lightenDarkenColor(campaignPrimaryColor,150)} 0%, ${lightenDarkenColor(campaignPrimaryColor,150)} 100%)!important;
              }
            `;

            Survey.StylesManager.applyTheme();

            console.log('modified defaultThemeColors: ', defaultThemeColors);

            if (singlePageMode) {
              console.log("user had passed in a query parameter to force single page mode, let's do it");
              this.state.json.questionsOnPageMode = "singlePage";
            }

            if (forceResponseReview) {
              console.log("user had passed in a query parameter to force the response preview, let's do it");
              this.state.json.showPreviewBeforeComplete = "showAllQuestions";
            }

            if (forceLocale != null) {
              console.log("user had passed in a query parameter to force the locale, let's do it");
              this.state.json.locale = forceLocale;

              console.log('the locale to use is: ', forceLocale);
            }

            console.log("here is the json used to set up the survey:", this.state.json);

            const model = new Survey.Model(this.state.json);
            console.log('model: ', model);

            // let's check -- if they haven't done anything yet, then we can show under construction...
            if (model.getAllQuestions().length > 0) {
              console.log("we have questions, let's do this");

              // this is the part where we actually load the survey

              let surveyRender = !this.state.isCompleted ? (
                <Survey.Survey
                  model={model}
                  onStarted={this.onStartedComponent}
                  onComplete={this.onCompleteComponent}
                  onUploadFiles={this.uploadFileToBlob}
                  onCurrentPageChanged={(e, val) => this.onCurrentPageChanged(e, val)}
                  css={customCSS}
                />
              ) : null;
              let onCompleteComponent = this.state.isCompleted ? (
                <div>The component after onComplete event</div>
              ) : null;

              console.log('surveyRender: ', surveyRender);

              let bgstyle = { position: 'relative' };
              let maskstyle = { position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%' };

              let favicon = require("../images/civica_favicon-32x32.ico").default;

              console.log('campaignUseBgColor: ', campaignUseBgColor);

              if (campaignUseBgColor) {
                console.log("campaign is set to use bg color");

                const masksetting = this.convertBrightnessToInversePercent(campaignBgMask);

                console.log(`campaignBgColor: ${campaignBgColor}`);
                console.log(`masksetting: ${masksetting}`);

                bgstyle.backgroundColor = campaignBgColor;
                maskstyle.backgroundColor = `rgba(0, 0, 0, ${masksetting})`;
              } else {
                console.log("campaign should show bg image");

                const imagemasksetting = this.convertBrightnessToPercent(campaignBgMask);

                console.log(`bgimage: ${campaignImage}`);
                console.log(`imagemasksetting: ${imagemasksetting}`);

                bgstyle.backgroundImage = `url(${campaignImage})`;
                maskstyle.backdropFilter = `brightness(${imagemasksetting})`;
              }


              // setting a start time
              this.state.surveyStartedAt = moment().utc();

              return (
                <>

                  <Helmet>
                    <meta charSet="utf-8" />
                    {typeof(this.state.json.title) == "object"?<title>{this.state.json.title.default}</title>: <title>{this.state.json.title}</title>}
                    <link rel="shortcut icon" href={favicon} />
                    <link rel="apple-touch-icon" href={favicon} />
                  </Helmet>
                  <div className={`${campaignJustification === 'center' ? 'page-centered' : 'page'} ${campaignCardShadow ? 'cardshadow' : 'nocardshadow'}`} style={bgstyle}>
                    <div style={maskstyle}></div>
                    <img className={`logo logo-${campaignLogoPosition.replace(' ', '-').toLowerCase()}`} src={campaignLogo}  />
                    <div className="survey-wrapper" style={{ width: `${(campaignWidth < 40) ? '40' : campaignWidth}%` }}>
                      {surveyRender}
                    </div>
                    {onCompleteComponent}
                    {/*--forced components start---*/}
                    {/*<Jqueryuidatepicker />*/}
                    {/*<Select2TagboxWidget />*/}
                    {/*<Nouislider />*/}
                    {/*<Sortablejs />*/}
                    {/*<Inputmask />*/}

                    {/*<Widgets />*/}

                    {/*<Sampledatepicker />*/}

                    {/*<JqueryUiDatepicker />*/}
                    {/*<Signaturepad />*/}
                    {/*<InputmaskWidget />*/}
                    {/*<Nouislider />*/}
                    {/*<Select2TagboxWidget />*/}
                    {/*<Sortablejs />*/}
                    {/*--forced components ends---*/}
                    <ToastContainer enableMultiContainer containerId="cookieConsent" />
                  </div>
                </>
              );


            } else {
              // render the under construction page
              console.log("survey was found but had no questions...");
              return (
                <ConstructionPage />
              )
            }
          }
        } // ends survey was found and is online, render ready to return an actual survey component

      } else {
        console.log("survey was not found, render returning the error page");
        return (
          <ErrorPage />
        )
      }
    } else {
      console.log("returning loader");
      return (
        <div className="loader-wrapper">
          <div id="advanced" className="circle"></div>
          <div className="image">
          </div>
        </div>
      )
    }
  }
}

export default SurveyPage;