import React from 'react';

const value = {
    status: 'OK'
}

class StatusPage extends React.Component {
  render() {

    return (
       <pre>
           <code>
                {JSON.stringify(value, null, 2)}
           </code>
       </pre>
    );
  }
}

export default StatusPage;