import React from 'react';
// utilities
import root from 'window-or-global';
// styles
import "../styles-josh.css";

import Helmet from "react-helmet";

const favicon = require("../images/unavailable.png").default;

let styles = {
  background: {
    position: 'relative',
    width: '100%',
    height: '100vh',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media screen and (minWidth: 768px)': {
      width: '100vw'
    }
  },
  backgroundOverlay: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.7)'
    // background: 'linear-gradient(to bottom right, rgba(96, 195, 243, 0.9) 0%, rgba(123, 16, 230, 0.9) 80%)'
  },
 
  
}
class UnavailablePage extends React.Component {

  componentDidMount() {
    this.handleBtnClick = this.handleBtnClick.bind(this);
  }

  handleBtnClick() {
    console.log('handlebutnclick');
    root.history.back();
  }


  render() {
    if (this.props.fullscreen) {
      styles.background.height = '100vh';
    }

    console.log('this.props.reason: ', this.props.reason);

    switch(this.props.reason){
      case "tooearly":
        console.log('switching background for too early');
        styles.background.backgroundImage = `url(${require("../images/waiting.jpg").default})`;
      break;

      case "toolate":
        console.log('switching background for too late');
        styles.background.backgroundImage = `url(${require("../images/toolate.jpg").default})`;
      break;

      case "offline":
      default:
        console.log('switching background for offline');
        styles.background.backgroundImage = `url(${require("../images/offline.jpg").default})`;
      break;
    }

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Unavailable</title>
          <link rel="shortcut icon" href={favicon} />
          <link rel="apple-touch-icon" href={favicon} />
        </Helmet>
        <div style={styles.background}>
          <div style={styles.backgroundOverlay}></div>
          <div className="errorpage-box">
            <h1 className="unavilablepage-title">Sorry, this survey is currently unavailable</h1>
            <p className="errorpage-subtitle">{decodeURI(this.props.message)}</p>
            <button
              className="errorpage-button"
              onClick={this.handleBtnClick}
            >Go Back</button>
          </div>
        </div>
      </>
    );
  }
}

export default UnavailablePage;
